var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[_c('checkbox-input',{attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': this,
        'key': 'export_chat_config',
      }
    }}}),(_vm.taggedTriggers.length)?_c('select-input',{staticClass:"scroll-multi-select",attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': this,
        'key': 'export_triggers',
        'options': _vm.getTaggedTriggerOptions,
        'multiple': true,
        'selectAll': true,
      }
    }}}):_vm._e(),_c('a-button',{staticClass:"mt-5",attrs:{"block":"","type":"primary","disabled":!_vm.export_chat_config && _vm.export_triggers.length === 0},on:{"click":_vm.exportSettings}},[_vm._v(" "+_vm._s(_vm.$t('export_button'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }