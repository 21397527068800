




































import NetworkNameInput from "@/components/networks/NetworkNameInput.vue";
import { applyNetworkConfigToGroup } from "@/includes/logic/Networks/logic";
import { successNotification } from "@/includes/NotificationService";
import { InputSetups } from "@/mixins/input-setups";
import { NetworkConfigImport, NetworkImportTypeEnum } from "@/includes/types/ImportConfig.types";
import { ImportTypeEnum } from "@/components/chat/ChatExtra/ImportConfig/types";

import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue';
import RadioInput from 'piramis-base-components/src/components/Pi/fields/RadioInput/RadioInput.vue';
import { SelectOption } from "piramis-base-components/src/logic/types";

import { Component, Emit, Mixins, Prop, PropSync } from 'vue-property-decorator';
import { cloneDeep } from "lodash";

@Component({
  components: {
    NetworkNameInput,
    RadioInput,
    SelectInput,
  }
})
export default class ImportConfigFromNetwork extends Mixins(InputSetups) {
  @PropSync('importConfigLoading', { type: Boolean, required: true, default: false }) isLoading!: boolean

  @Prop({ required: false, default: null }) importModes!: Array<ImportTypeEnum>

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  @Emit()
  done() {
    return null
  }

  model = this.defaultModel()

  get networkConfigImportTypes(): Array<SelectOption> {
    return [
      ...!this.importModes || this.importModes.includes(ImportTypeEnum.Triggers) ? [ {
        label: this.$t(`import_config_network_import_type_${ NetworkImportTypeEnum.Triggers.toLowerCase() }`),
        value: NetworkImportTypeEnum.Triggers
      } ] : [],
      ...!this.importModes || this.importModes.includes(ImportTypeEnum.Modules) ? [ {
        label: this.$t(`import_config_network_import_type_${ NetworkImportTypeEnum.Modules.toLowerCase() }`),
        value: NetworkImportTypeEnum.Modules
      } ] : [],
    ]
  }

  applyNetworkConfigToGroupVm() {
    this.isLoading = true

    applyNetworkConfigToGroup(this.model)
      .then(res => {
        if (res) {
          successNotification()

          this.model = this.defaultModel()

          this.done()
        }
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  defaultModel(): NetworkConfigImport {
    return cloneDeep({
      network: '',
      chat_id: this.$store.getters.chatId,
      import_types: []
    })
  }

  created() {
    if (this.networkConfigImportTypes.length === 1) {
      this.model.import_types = [ this.networkConfigImportTypes[0].value ]
    }
  }
}
